@import "@styles/index.scss";
.container {
  width: 100%;
}

.statusCard {
  @include card;
  padding: 40px;
  width: 70%;
  min-height: 24vh;
}

.statusIcon {
  font-size: 72px;
  color: $secondaryFontColor !important;
}

.textSecondaryColor {
  color: $secondaryFontColor !important;
}

.detailedMessage {
  @extend .textSecondaryColor;
  padding-top: 20px;
}

.title {
  text-align: center;
}

.titleAccent {
  @extend .title;
  color: $accentFontColor !important;
}

.consentLabel {
  color: $secondaryFontColor !important;
}
